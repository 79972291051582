import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import NbOrders from './nbOrder';
import reportWebVitals from './reportWebVitals';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import API from './env';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import 'moment/locale/fr'
// import Moment from 'react-moment';
require('moment-timezone');

class Body extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      heures:[],
      deliveryAvailability : [],
      deliveryAvailabilityLivraison :[],
      maxOrdersPerSlot : 0,
      maxOrdersPerSlotLivraison : 0,
      timeSlot : 0,
      today : moment().locale('fr'),
      currentDay : moment().locale('fr'),
      numOfDay : 0,
      isNight: false,
      creneauNow : "",
      heureMin : "",
      livraisonStart:"",
      heureMax : "",
      livraisonEnd:"",
      heure : "",
      orders:[],
      ordersLivraison:[],
      orderPerHour:0,
      currentDeleteOrder : false,
      APIerror : false,
      dataError : false,
      firstTimeSlot : {minFirstTimeSlot : "", maxFirstTimeSlot : ""},
      firstTimeSlotLivraison : {minFirstTimeSlotLivraison : "", maxFirstTimeSlotLivraison : ""},
      livraisonOnly : false,
      POS_Id : {}, 

    };
    
  }
  verifyDelivery = async (deliverys) =>{
    let res = deliverys.map(function(delivery) {
      if (delivery === null || delivery === undefined){
        delivery = {maxHour1: "Invalid date", maxHour2: "Invalid date", minHour1: "Invalid date", minHour2: "Invalid date",}
      }
      return delivery;
    });
    return res;
  }
  Assign = async (data, dataLivraison,creneau) =>{

    if (!dataLivraison) {
      dataLivraison = {
        _id: "TODO",
        deliveryAvailability : [
          {
              "minHour2": null,
              "maxHour2": null,
              "maxHour1": null,
              "minHour1": null
          }],
        maxOrdersPerSlot : 0,
        orders : []
      }
    }


    if(data._id && dataLivraison._id){
      this.setState({POS_Id : {normal : data._id, livraison: dataLivraison._id}});
    }
    if(data.deliveryAvailability !== null ){
      let tab = await this.verifyDelivery(data.deliveryAvailability);
      data.deliveryAvailability = tab;
      await this.setState({deliveryAvailability : data.deliveryAvailability});
    }else{
      await this.setState({dataError : true});
    }
    if(data.maxOrdersPerSlot !== null ){
      await this.setState({maxOrdersPerSlot : data.maxOrdersPerSlot});
    }else{
      await this.setState({dataError : true});
    }
    if(data.timeSlot !== null ){
      await this.setState({timeSlot : data.timeSlot});
    }else{
      await this.setState({dataError : true});
    }
    if(data.orders !== null ){
      await this.setState({orders : data.orders});
    }else{
      await this.setState({dataError : true});
    };
    if(!creneau){
      await this.setState({creneauNow : this.creneau()})
    }
    //Livraison
    if(dataLivraison.deliveryAvailability !== null ){
      let tab = await this.verifyDelivery(dataLivraison.deliveryAvailability);
      dataLivraison.deliveryAvailability = tab;
      await this.setState({deliveryAvailabilityLivraison : dataLivraison.deliveryAvailability});
    }else{
      await this.setState({dataError : true});
    }
    if(dataLivraison.maxOrdersPerSlot !== null ){
      await this.setState({maxOrdersPerSlotLivraison : dataLivraison.maxOrdersPerSlot});
    }else{
      await this.setState({dataError : true});
    }
    if(dataLivraison.orders !== null ){
      await this.setState({ordersLivraison : dataLivraison.orders});
    }else{
      await this.setState({dataError : true});
    };

    let minTimeSlot = data.deliveryAvailability[this.SwitchCase(moment(this.state.currentDay).format("dddd"))];
    let minTimeSlotLivraison = dataLivraison.deliveryAvailability[this.SwitchCase(moment(this.state.currentDay).format("dddd"))];

    if (moment(this.state.currentDay).format("HH:mm") > moment(minTimeSlot.maxHour1,("HH:mm")).format("HH:mm") && minTimeSlotLivraison){
      minTimeSlot = {minFirstTimeSlot : minTimeSlot.minHour2, maxFirstTimeSlot : minTimeSlot.maxHour2, isNight : true}
      minTimeSlotLivraison = {minFirstTimeSlotLivraison : minTimeSlotLivraison.minHour2, maxFirstTimeSlotLivraison : minTimeSlotLivraison.maxHour2, isNight : true}
    } else if (moment(this.state.currentDay).format("HH:mm") <= moment(minTimeSlot.maxHour1,("HH:mm")).format("HH:mm") && minTimeSlotLivraison) {
      minTimeSlot = {minFirstTimeSlot : minTimeSlot.minHour1, maxFirstTimeSlot : minTimeSlot.maxHour1, isNight : false}
      minTimeSlotLivraison = {minFirstTimeSlotLivraison : minTimeSlotLivraison.minHour1, maxFirstTimeSlotLivraison : minTimeSlotLivraison.maxHour1, isNight : false}
    } else if (moment(this.state.currentDay).format("HH:mm") > moment(minTimeSlot.maxHour1,("HH:mm")).format("HH:mm") && !minTimeSlotLivraison) {
      minTimeSlot = {minFirstTimeSlot : minTimeSlot.minHour2, maxFirstTimeSlot : minTimeSlot.maxHour2, isNight : true}
    } else {
      minTimeSlot = {minFirstTimeSlot : minTimeSlot.minHour1, maxFirstTimeSlot : minTimeSlot.maxHour1, isNight : false}
    }

    await this.setState({firstTimeSlot : minTimeSlot});
    await this.setState({firstTimeSlotLivraison : minTimeSlotLivraison});
  }
  SwitchCase(props) {
    switch(props) {
      case 'lundi':
        return 0;
      case 'mardi':
        return 1;
      case 'mercredi':
        return 2;
      case 'jeudi':
        return 3;
      case 'vendredi':
        return 4;
      case 'samedi':
        return 5;
      case 'dimanche':
        return 6;
      default:
        console.log(`Sorry, we are out of ${props}.`);
    }
  }
  nextDay = async () =>{
    await this.reset();
    await this.setState({heures : []})
    await this.setState({currentDay : moment(this.state.currentDay).add(1, 'days')})
    await this.updateRender(moment(this.state.currentDay).format("dddd"), this.state.isNight)
  }
  previousDay = async () =>{
    await this.reset();
    await this.setState({heures : []})
    await this.setState({currentDay : moment(this.state.currentDay).add(-1, 'days')})
    if(moment(this.state.currentDay).format("dddd")< moment(this.state.today).format("dddd")){
      if(this.state.creneauNow === "creneau 1"){
        await this.updateRender(moment(this.state.today).format("dddd"), false)
      }else{
        await this.updateRender(moment(this.state.today).format("dddd"), true)
      }
    }else{
      await this.updateRender(moment(this.state.currentDay).format("dddd"), this.state.isNight)
    }
  }
  nextPlage = async () =>{
    await this.reset();
    await this.setState({heures : []})
    if (this.state.isNight){
      await this.setState({isNight : false})
      await this.setState({currentDay : moment(this.state.currentDay).add(1, 'days')})
    } else {
      await this.setState({isNight : true})
    }
    await this.updateRender(moment(this.state.currentDay).format("dddd"), this.state.isNight)
  }
  previousPlage = async () =>{
    await this.reset();
    await this.setState({heures : []})
    if (this.state.isNight){
      await this.setState({isNight : false})
    } else {
      await this.setState({isNight : true})
      await this.setState({currentDay : moment(this.state.currentDay).add(-1, 'days')})
    }
    if(moment(this.state.currentDay).format("dddd")< moment(this.state.today).format("dddd")){
      if(this.state.creneauNow === "creneau 1"){
        await this.updateRender(moment(this.state.today).format("dddd"), false)
      }else{
        await this.updateRender(moment(this.state.today).format("dddd"), true)
      }
    }else{
      await this.updateRender(moment(this.state.currentDay).format("dddd"), this.state.isNight)
    }
  }
  now = async () =>{
    await this.reset();
    await this.setState({currentDay : moment()})
    await this.setDayOfWeek();
    if(moment(this.state.deliveryAvailability[this.state.numOfDay].maxHour1,'HH:mm').format("HH:mm")<moment(this.state.currentDay).format("HH:mm")){
      await this.setState({isNight : true});
    }
    await this.updateRender(moment(this.state.currentDay).format("dddd"), this.state.isNight)
  }
  reset = async () => {
    await this.setState({heures : []})
    await this.view()
  }
  async setDayOfWeek(day){
    if(day === undefined){
      await this.setState({numOfDay : this.SwitchCase(moment(this.state.today).format("dddd"))});
    } else{
      await this.setState({numOfDay : this.SwitchCase(day)});
    }
  }
  async timeOfTheDay(isNight, numOfDay){
    if(this.state.deliveryAvailability[numOfDay] === null){
      await this.setState({dataError : "dayNullNormal"});
    }else if(this.state.deliveryAvailabilityLivraison[numOfDay] === null) {
      await this.setState({dataError : "dayNullLivraison"});
    }else if (this.state.deliveryAvailability[numOfDay] && this.state.deliveryAvailabilityLivraison[numOfDay]){
      let heureMin, heureMax, heureMinLivraison, heureMaxLivraison;
      if(isNight === false || undefined){
        heureMin = this.state.deliveryAvailability[numOfDay].minHour1 ; 
        heureMax = this.state.deliveryAvailability[numOfDay].maxHour1 ;
        heureMinLivraison = this.state.deliveryAvailabilityLivraison[numOfDay].minHour1 ; 
        heureMaxLivraison = this.state.deliveryAvailabilityLivraison[numOfDay].maxHour1 ;
      }else{
        heureMin = this.state.deliveryAvailability[numOfDay].minHour2 ;
        heureMax = this.state.deliveryAvailability[numOfDay].maxHour2 ;
        heureMinLivraison = this.state.deliveryAvailabilityLivraison[numOfDay].minHour2 ; 
        heureMaxLivraison = this.state.deliveryAvailabilityLivraison[numOfDay].maxHour2 ;
      }
      await this.setState({heureMin : heureMin}) ;
      await this.setState({heureMax : heureMax}) ;
      await this.setState({livraisonStart : heureMinLivraison}) ;
      await this.setState({livraisonEnd : heureMaxLivraison}) ;
      await this.setState({heure : moment(this.state.heureMin,'HH:mm').format('HH:mm')});
    }else if (this.state.deliveryAvailability[numOfDay]){
      let heureMin, heureMax, heureMinLivraison, heureMaxLivraison;
      if(isNight === false || undefined){
        heureMin = this.state.deliveryAvailability[numOfDay].minHour1 ; 
        heureMax = this.state.deliveryAvailability[numOfDay].maxHour1 ;
        heureMinLivraison = "Invalid date" ; 
        heureMaxLivraison = "Invalid date" ;
      }else{
        heureMin = this.state.deliveryAvailability[numOfDay].minHour2 ;
        heureMax = this.state.deliveryAvailability[numOfDay].maxHour2 ;
        heureMinLivraison = "Invalid date" ; 
        heureMaxLivraison = "Invalid date" ;
      }
      await this.setState({heureMin : heureMin}) ;
      await this.setState({heureMax : heureMax}) ;
      await this.setState({livraisonStart : heureMinLivraison}) ;
      await this.setState({livraisonEnd : heureMaxLivraison}) ;
      await this.setState({heure : moment(this.state.heureMin,'HH:mm').format('HH:mm')});
    }else{
      await this.setState({dataError : "dayMissing"});
    }
  }
  async timeSlots(){
    let heureStart = this.state.heureMin;
    if (!this.state.currentDeleteOrder){
      await this.setState({heures : []})
    }
    if(this.state.heure!=="Invalid date"){
      this.setState({livraisonOnly: false})
      if(this.state.livraisonStart !== null && this.state.livraisonEnd !== null){
        if(this.state.heure>moment(this.state.livraisonStart,"HH:mm").format("HH:mm")){
          await this.setState({heure:moment(this.state.livraisonStart,"HH:mm").format("HH:mm")});
          heureStart=moment(this.state.livraisonStart,"HH:mm").format("HH:mm");
        }
        this.state.heures.push(moment(this.state.heure,"HH:mm").format("HH:mm"));
        let min = 0;
        if(this.state.heureMax>=this.state.livraisonEnd || this.state.livraisonEnd === undefined || this.state.livraisonEnd === null || this.state.livraisonEnd === "Invalid date"){
          while(moment(this.state.heure,'HH:mm').format('HH:mm') < moment(this.state.heureMax,'HH:mm').format('HH:mm')){
            min = min + this.state.timeSlot;
            await this.setState({heure : moment(heureStart,'HH:mm').add(min, 'minutes').format("HH:mm")});
            this.state.heures.push(moment(this.state.heure,"HH:mm").format("HH:mm"));
          }
        }else{
          while(moment(this.state.heure,'HH:mm').format('HH:mm') < moment(this.state.livraisonEnd,'HH:mm').format('HH:mm')){
            min = min + this.state.timeSlot;
            await this.setState({heure : moment(heureStart,'HH:mm').add(min, 'minutes').format("HH:mm")});
            this.state.heures.push(moment(this.state.heure,"HH:mm").format("HH:mm"));
          }
        }
      }else{
        this.state.heures.push(moment(this.state.heure,"HH:mm").format("HH:mm"));
        let min = 0;
        while(moment(this.state.heure,'HH:mm').format('HH:mm') < moment(this.state.heureMax,'HH:mm').format('HH:mm')){
          min = min + this.state.timeSlot;
          await this.setState({heure : moment(heureStart,'HH:mm').add(min, 'minutes').format("HH:mm")});
          this.state.heures.push(moment(this.state.heure,"HH:mm").format("HH:mm"));
        }
      } 
    }else{
      if(this.state.livraisonStart){
        this.state.heures.push(moment(this.state.livraisonStart,"HH:mm").format("HH:mm"));
        this.setState({heure: this.state.livraisonStart})
        this.setState({livraisonOnly: true})
        let min = 0;
        while(moment(this.state.heure,'HH:mm').format('HH:mm') < moment(this.state.livraisonEnd,'HH:mm').format('HH:mm')){
          min = min + this.state.timeSlot;
          await this.setState({heure : moment(this.state.livraisonStart,'HH:mm').add(min, 'minutes').format("HH:mm")});
          this.state.heures.push(this.state.heure);
        }
      }else{
        this.state.heures.push(this.state.heure);
      }
    }
    
  }
  async view(){
    const maxOrder = this.state.maxOrdersPerSlot;
    const maxOrderLivraison = this.state.maxOrdersPerSlotLivraison;
    let orders = this.state.orders;
    let ordersLivraison = this.state.ordersLivraison;
    const currentDay = this.state.currentDay; 
    const heureMax = this.state.heures[this.state.heures.length-1];
    const livraisonEnd = this.state.livraisonEnd;
    const livraisonStart = this.state.livraisonStart;
    const livraisonOnly = this.state.livraisonOnly;
    const normalEnd = this.state.heureMax;
    const normalStart = this.state.heureMin;
    const POS_Id = this.state.POS_Id;
    const timeSlot = this.state.timeSlot;
    let list = this.state.heures.map(function(heure1) {
      const date = moment(currentDay).format('YYYY-MM-DD')+" "+ heure1;
      const dateLivraison = moment(currentDay).format('YYYY-MM-DD')+" "+ heure1;
      // const dateLivraison = moment(date,"YYYY-MM-DD HH:mm").add(timeSlot,'minutes').format('YYYY-MM-DD HH:mm');  **** avec decallage
      if(heure1 === "Invalid date"){
        return(
          <div className="App" key={heure1.toString()}>
            <ListItem>
              <ListItemText style={{textAlign: 'center'}} primary="restaurant fermé" />
            </ListItem>
            <Divider />
          </div>
        );
      }else if(heure1 === "Pas de data"){
        return(
          <div className="App" key={heure1.toString()}>
            <ListItem>
              <ListItemText style={{textAlign: 'center'}} primary="Pas d'horaire pour ce jour" />
            </ListItem>
            <Divider />
          </div>
        );
      }else {
       if(moment(heureMax, "HH:mm").format("HH:mm") >= moment(heure1, "HH:mm").format("HH:mm") && livraisonOnly === false && moment(livraisonEnd, "HH:mm").format("HH:mm")<=moment(normalEnd, "HH:mm").format("HH:mm") && moment(livraisonStart, "HH:mm").format("HH:mm")>=moment(normalStart, "HH:mm").format("HH:mm")){
        // if(moment(livraisonEnd, "HH:mm").format("HH:mm") >= moment(heure1, "HH:mm").add(timeSlot,"minutes").format("HH:mm") && moment(livraisonStart, "HH:mm").format("HH:mm") <= moment(heure1, "HH:mm").add(timeSlot,"minutes").format("HH:mm")){    ***** avec decallage
        if(moment(livraisonEnd, "HH:mm").format("HH:mm") >= moment(heure1, "HH:mm").format("HH:mm") && moment(livraisonStart, "HH:mm").format("HH:mm") <= moment(heure1, "HH:mm").format("HH:mm")){
            let nbOrdersPassed = 0;
          let nbOrdersPassedDelivery = 0;
          orders.map(function(order) {
            if(orders){
              let dateParis = moment.tz(order.date,"Europe/Lisbon")
              dateParis = dateParis.clone().tz("Europe/Paris");
              if(moment(dateParis).format("YYYY-MM-DD HH:mm") === moment(moment(currentDay).format('YYYY-MM-DD')+" "+ moment(heure1,"HH:mm").format("HH:mm")).format('YYYY-MM-DD HH:mm')){
                nbOrdersPassed += 1;
              }
              return nbOrdersPassed;
            }
            return nbOrdersPassed;
          });
          ordersLivraison.map(function(order) {
            let dateParis = moment.tz(order.date,"Europe/Lisbon")
            dateParis = dateParis.clone().tz("Europe/Paris");
            if(moment(dateParis).format("YYYY-MM-DD HH:mm") === moment(moment(currentDay).format('YYYY-MM-DD')+" "+ moment(heure1,"HH:mm").format("HH:mm")).format('YYYY-MM-DD HH:mm')){
              nbOrdersPassedDelivery += 1;
            }
            return nbOrdersPassedDelivery;
          });
          return (
            <div className="App" key={heure1.toString()}>
              <NbOrders display = "all" maxOrderNormal={maxOrder} normalOrders={nbOrdersPassed} date = {date} maxOrderLivraison={maxOrderLivraison} livraisonOrders = {nbOrdersPassedDelivery} dateLivraison = {dateLivraison} POS_Id={POS_Id}/>
            <Divider />
            </div>
          );
         }else{
          let nbOrdersPassed = 0;
          
          orders.map(function(order) {
            if(order){
              let dateParis = moment.tz(order.date,"Europe/Lisbon")
              dateParis = dateParis.clone().tz("Europe/Paris");
              if(moment(dateParis).format("YYYY-MM-DD HH:mm") === moment(moment(currentDay).format('YYYY-MM-DD')+" "+ moment(heure1,"HH:mm").format("HH:mm")).format('YYYY-MM-DD HH:mm')){
                nbOrdersPassed += 1;
              }
              return nbOrdersPassed;
            }
            return nbOrdersPassed;
          });
          if(nbOrdersPassed<=maxOrder){
            return (
              <div className="App" key={heure1.toString()}>
              <NbOrders display="all" maxOrderNormal={maxOrder} normalOrders={nbOrdersPassed} date = {date} livraisonOrders = {undefined} POS_Id={POS_Id}/>
              <Divider />
              </div>
            );
          }
         }
        }else if (livraisonOnly){
          if(moment(livraisonEnd, "HH:mm").format("HH:mm") >= moment(heure1, "HH:mm").format("HH:mm") && moment(livraisonStart, "HH:mm").format("HH:mm") <= moment(heure1, "HH:mm").format("HH:mm")){
            let nbOrdersPassedDelivery = 0;
            if(ordersLivraison){
              ordersLivraison.map(function(order) {
                if(order){
                  let dateParis = moment.tz(order.date,"Europe/Lisbon")
                  dateParis = dateParis.clone().tz("Europe/Paris");
                  if(moment(dateParis).format("YYYY-MM-DD HH:mm") === moment(moment(currentDay).format('YYYY-MM-DD')+" "+ moment(heure1,"HH:mm").format("HH:mm")).format('YYYY-MM-DD HH:mm')){
                    nbOrdersPassedDelivery += 1;
                  }
                  return nbOrdersPassedDelivery;
                }
                return nbOrdersPassedDelivery;
              });
            }
            return (
              <div className="App" key={heure1.toString()}>
                <NbOrders display="livraisonOnly" maxOrderLivraison={maxOrderLivraison} livraisonOrders = {nbOrdersPassedDelivery} dateLivraison = {date} normalOrders={undefined} POS_Id={POS_Id}/>
              <Divider />
              </div>
            );
          }
        }else if(livraisonOnly === false && (moment(livraisonEnd,"HH:mm").format("HH:mm")>moment(normalEnd,"HH:mm").format("HH:mm")||moment(livraisonStart,"HH:mm").format("HH:mm")<=moment(normalStart,"HH:mm").format("HH:mm"))){
          let nbOrdersPassed = 0;
          let nbOrdersPassedDelivery = 0;
          if( moment(heure1,"HH:mm").format("HH:mm")<=moment(normalEnd,"HH:mm").add(timeSlot,"minutes").format("HH:mm") && moment(heure1,"HH:mm").format("HH:mm")>=moment(normalStart,"HH:mm").add(timeSlot,"minutes").format("HH:mm") && moment(heure1,"HH:mm").format("HH:mm")<=moment(livraisonEnd,"HH:mm").format("HH:mm") && moment(heure1,"HH:mm").format("HH:mm")>=moment(livraisonStart,"HH:mm").format("HH:mm")){
            orders.map(function(order) {
              if(order){
                let dateParis = moment.tz(order.date,"Europe/Lisbon")
                dateParis = dateParis.clone().tz("Europe/Paris");
                if(moment(dateParis).format("YYYY-MM-DD HH:mm") === moment(moment(currentDay).format('YYYY-MM-DD')+" "+ moment(heure1,"HH:mm").add(-timeSlot,'minutes').format("HH:mm")).format('YYYY-MM-DD HH:mm')){
                  nbOrdersPassed += 1;
                }
                return nbOrdersPassed;
              }
              return nbOrdersPassed;
            });
            ordersLivraison.map(function(order) {
              if(order){
                let dateParis = moment.tz(order.date,"Europe/Lisbon")
                dateParis = dateParis.clone().tz("Europe/Paris");
                if(moment(dateParis).format("YYYY-MM-DD HH:mm") === moment(moment(currentDay).format('YYYY-MM-DD')+" "+ moment(heure1,"HH:mm").format("HH:mm")).format('YYYY-MM-DD HH:mm')){
                  nbOrdersPassedDelivery += 1;
                }
                return nbOrdersPassedDelivery;
              }
              return nbOrdersPassedDelivery;
            });
            return (
              <div className="App" key={heure1.toString()}>
              <NbOrders display="all" maxOrderNormal={maxOrder} normalOrders={nbOrdersPassed} date = {moment(date,'YYYY-MM-DD HH:mm').add(-timeSlot,'minutes').format('YYYY-MM-DD HH:mm')} maxOrderLivraison={maxOrderLivraison} livraisonOrders = {nbOrdersPassedDelivery} dateLivraison = {date} POS_Id={POS_Id}/>

              <Divider />
              </div>
            );
          }else if (moment(heure1,"HH:mm").format("HH:mm")<=moment(livraisonEnd,"HH:mm").format("HH:mm") && moment(heure1,"HH:mm").format("HH:mm")>=moment(livraisonStart,"HH:mm").format("HH:mm")){
            ordersLivraison.map(function(order) {
              if(order){
                let dateParis = moment.tz(order.date,"Europe/Lisbon")
                dateParis = dateParis.clone().tz("Europe/Paris");
                if(moment(dateParis).format("YYYY-MM-DD HH:mm") === moment(moment(currentDay).format('YYYY-MM-DD')+" "+ moment(heure1,"HH:mm").format("HH:mm")).format('YYYY-MM-DD HH:mm')){
                  nbOrdersPassedDelivery += 1;
                }
                return nbOrdersPassedDelivery;
              }
              return nbOrdersPassedDelivery;
            });
            return (
              <div className="App" key={heure1.toString()}>
                <NbOrders display = "all" nbOrder={maxOrderLivraison} maxOrderLivraison={maxOrderLivraison} livraisonOrders={nbOrdersPassedDelivery} normalOrders = {undefined} dateLivraison = {date} POS_Id={POS_Id} type ="livraison" livraisonOnly = "true"/>
               
              <Divider />
              </div>
            );
          }else{
            orders.map(function(order) {
              if(order){
                let dateParis = moment.tz(order.date,"Europe/Lisbon")
                dateParis = dateParis.clone().tz("Europe/Paris");
                if(moment(dateParis).format("YYYY-MM-DD HH:mm") === moment(moment(currentDay).format('YYYY-MM-DD')+" "+ moment(heure1,"HH:mm").format("HH:mm")).format('YYYY-MM-DD HH:mm')){
                  nbOrdersPassed += 1;
                }
                return nbOrdersPassed;
              }
              return nbOrdersPassed;
            });
            return (
              <div className="App" key={heure1.toString()}>
              <NbOrders display="all" maxOrderNormal={maxOrder} normalOrders={nbOrdersPassed} date = {date} livraisonOrders = {undefined} POS_Id={POS_Id}/>
              <Divider />
              </div>
            );
          }
        }
      }return(0);
    });
    await this.setState({list : list});
  }
  async updateRender(day, isNight){
    await this.setDayOfWeek(day);
    await this.timeOfTheDay(isNight,this.state.numOfDay);
    if(this.state.heureMin !== null){
      await this.setState({currentDay : moment((moment(this.state.currentDay).format("YYYY-MM-DD")+ " " + this.state.heureMin),'YYYY-MM-DD HH:mm')})
    }else {
      let heureTmp ="";
      if(isNight === true){
        heureTmp = "16:00";
      } else {
        heureTmp = "8:00";
      }
      await this.setState({currentDay : moment((moment(this.state.currentDay).format("YYYY-MM-DD")+ " " + heureTmp),'YYYY-MM-DD HH:mm')})
    }
    
    await this.timeSlots();
    await this.view()
  }
  deleteOldOrders = async () => {
    let lastConnexion = moment(localStorage.getItem('lastConnexion'),"YYYY-MM-DD").format("YYYY-MM-DD");
    while(moment(lastConnexion, "YYYY-MM-DD").format("YYYY-MM-DD")< moment(this.state.currentDay).format("YYYY-MM-DD")){
      await this.setState({currentDay : moment(this.state.currentDay).add(-1, 'days')});
      await this.setState({currentDeleteOrder : true});
      await this.setDayOfWeek(moment(this.state.currentDay).format("dddd"));
      await this.timeOfTheDay(false, this.state.numOfDay);
      await this.timeSlots();
      await this.timeOfTheDay(true, this.state.numOfDay);
      await this.timeSlots();
      const date = moment(this.state.currentDay).format("YYYY-MM-DD");
      this.state.heures.map(function(heure1) {
        let normalOrders = JSON.parse(localStorage.getItem("normal"));
        let livraisonOrders = JSON.parse(localStorage.getItem("livraison"));
        if( normalOrders[moment(date + " " + heure1,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')]){
          delete normalOrders[moment(date + " " + heure1,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')];
          localStorage.setItem("normal", JSON.stringify(normalOrders));
        }
        if( livraisonOrders[moment(date + " " + heure1,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')]){
          delete livraisonOrders[moment(date + " " + heure1,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')];
          localStorage.setItem("livraison", JSON.stringify(livraisonOrders));
        }return(0);
      });
      await this.setState({currentDeleteOrder : false});
    }
    await this.setState({currentDay : moment()});
   
  }
  creneau (){
    const night=this.state.isNight
    if(!night){
      return "creneau 1"
    }else{
      return "creneau 2"
    }
  }
  async getData(){
    const response = await axios.get(`${API.APIurl}/api/pointsOfSale/populate`);
    await this.Assign(response.data[0],response.data[1], this.state.creneauNow)
    await this.reset();
    await this.setState({heures : []})
    await this.updateRender(moment(this.state.currentDay).format("dddd"), this.state.isNight);
    return response;
  }
  async componentDidMount () { 
    try{
      const res = await axios.get(`${API.APIurl}/api/pointsOfSale/populate`);
      await this.Assign(res.data[0], res.data[1]);
      await localStorage.setItem('lastConnexion', moment().format('YYYY-MM-DD'));
      await this.setDayOfWeek();
      if(moment(this.state.deliveryAvailability[this.state.numOfDay].maxHour1,'HH:mm').format("HH:mm")<moment(this.state.currentDay).format("HH:mm") || moment(this.state.deliveryAvailabilityLivraison[this.state.numOfDay].maxHour1,'HH:mm').format("HH:mm")<moment(this.state.currentDay).format("HH:mm")){
        await this.setState({isNight : true});
      }
      await this.timeOfTheDay(this.state.isNight,this.state.numOfDay);
      await this.timeSlots();
      await this.view();
    }catch (Error){
      console.log(Error);
      if(Error === "Network Error"){
        alert('Impossible de se connecter a Aquila !');
        await this.setState({APIerror : true});
      }else {
        alert(Error);
      }
      
    }
    setInterval(await this.getData.bind(this), API.refresh);
  }
  render() {
    if (!this.state.APIerror && !this.state.dataError){
      if(this.state.livraisonEnd === null && this.state.livraisonStart === null && this.state.livraisonOnly === false && moment(this.state.today).format("MMM Do YY") === moment(this.state.currentDay).format("MMM Do YY") && this.state.creneauNow === this.creneau()){
        return (
          <header className="App-header">
            {/* <img rel="icon" src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
            {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
            <span>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
    
              </span>
              <List component="nav">
                <ListItem>
                  <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                </ListItem>
                <ListItem>
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:25+'px'}} primary="Sur place" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Internet" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:100+'px'}} primary="Livraison" />                  
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-105+'px'}} primary="Total" />                  
                </ListItem>
                  {this.state.list}
              </List>
          </header>
        )
      }else if(this.state.livraisonEnd === null && this.state.livraisonStart === null && this.state.livraisonOnly === false && moment(this.state.today).format("MMM Do YY") === moment(this.state.currentDay).format("MMM Do YY") && this.state.creneauNow !== this.creneau()){
        return (
          <header className="App-header">
            {/* <img rel="icon" src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
            {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
            <span>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                    <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousPlage}>Plage précédente</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
    
              </span>
              <List component="nav">
                <ListItem>
                  <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                </ListItem>
                <ListItem>
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Sur place" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Internet" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:100+'px'}} primary="Livraison" />                  
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                 
                </ListItem>
                  {this.state.list}
              </List>
          </header>
        )
      }else if(this.state.livraisonEnd === null && this.state.livraisonStart === null && this.state.livraisonOnly === false && moment(this.state.today).format("MMM Do YY") !== moment(this.state.currentDay).format("MMM Do YY") && this.state.creneauNow === 'creneau 2'&& this.state.creneauNow !== this.creneau()){
        return (
          <header className="App-header">
            {/* <img rel="icon" src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
            {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
            <span>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                    <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousPlage}>Plage précédente</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
    
              </span>
              <List component="nav">
                <ListItem>
                  <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                </ListItem>
                <ListItem>
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Sur place" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Internet" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:100+'px'}} primary="Livraison" />                  
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                 
                </ListItem>
                  {this.state.list}
              </List>
          </header>
        )
      }else if(this.state.livraisonEnd === null && this.state.livraisonStart === null && this.state.livraisonOnly === false && moment(this.state.today).format("MMM Do YY") !== moment(this.state.currentDay).format("MMM Do YY")){
        return (
          <header className="App-header">
            {/* <img rel="icon" src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
            {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
            <span>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousDay}>Jour précédent</Button>
                    </TableCell>
                    <TableCell>
                    <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousPlage}>Plage précédente</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
    
              </span>
              <List component="nav">
                <ListItem>
                  <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                </ListItem>
                <ListItem>
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Sur place" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Internet" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:100+'px'}} primary="Livraison" />                  
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                 
                </ListItem>
                  {this.state.list}
              </List>
          </header>
        )
      }
      if(this.state.livraisonOnly && moment(this.state.today).format("MMM Do YY") === moment(this.state.currentDay).format("MMM Do YY") && this.state.creneauNow === this.creneau()){
        return (
          <header className="App-header">
            {/* <img rel="icon" src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
            {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
            <span>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
    
              </span>
              <List component="nav">
                <ListItem>
                  <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                </ListItem>
                <ListItem>
                  <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:-50+'px', marginRight:110+'px'}} primary="Livraison" />                  
                  <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                  
                </ListItem>
                  {this.state.list}
              </List>
          </header>
        )
      }else if(this.state.livraisonOnly && moment(this.state.today).format("MMM Do YY") === moment(this.state.currentDay).format("MMM Do YY") && this.state.creneauNow !== this.creneau()){
        return (
          <header className="App-header">
            {/* <img rel="icon" src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
            {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
            <span>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                    <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousPlage}>Plage précédente</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
    
              </span>
              <List component="nav">
                <ListItem>
                  <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                </ListItem>
                <ListItem>
                  <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:110+'px'}} primary="Livraison" />                  
                  <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                  
                </ListItem>
                  {this.state.list}
              </List>
          </header>
        )
      }else if (this.state.livraisonOnly){
        return (
          <header className="App-header">
            {/* <img rel="icon" src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
            {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
            <span>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousDay}>Jour précédent</Button>
                    </TableCell>
                    <TableCell>
                    <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousPlage}>Plage précédente</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                    </TableCell>
                    <TableCell>
                      <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
    
              </span>
              <List component="nav">
                <ListItem>
                  <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                </ListItem>
                <ListItem>
                  <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:110+'px'}} primary="Livraison" />                  
                  <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                  
                </ListItem>
                  {this.state.list}
              </List>
          </header>
        )
      }else{
        if (moment(this.state.today).format("MMM Do YY") === moment(this.state.currentDay).format("MMM Do YY") && this.state.creneauNow === this.creneau()){
          return (
              <header className="App-header">
                {/* <img rel="icon" src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
                {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
                <span>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                        </TableCell>
                        <TableCell>
                          <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                        </TableCell>
                        <TableCell>
                          <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
        
                  </span>
                  <List component="nav">
                    <ListItem>
                      <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Sur place" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Internet" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:100+'px'}} primary="Livraison" />                  
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                  
                    </ListItem>
                      {this.state.list}
                  </List>
              </header>
            )
        }
        if(moment(this.state.today).format("MMM Do YY") === moment(this.state.currentDay).format("MMM Do YY") && this.state.creneauNow !== this.creneau()){
          return (
              <header className="App-header">
                {/* <img style={{float: "left"}} src="sukisuhi-logo.PNG" alt="Logo sukisushi" /> */}
                {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
                <span>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousPlage}>Plage précédente</Button>
                        </TableCell>
                        <TableCell>
                          <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                        </TableCell>
                        <TableCell>
                          <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                        </TableCell>
                        <TableCell>
                          <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
        
                  </span>
                  <List component="nav">
                    <ListItem>
                      <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Sur place" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Internet" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:100+'px'}} primary="Livraison" />                  
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                    
                    </ListItem>
                      {this.state.list}
                  </List>
              </header>
            )
        } else {
          return (
            <header className="App-header">
              {/* <img rel="icon" src="sukisuhi-logo.png" alt="Logo sukisushi" /> */}
              {moment(this.state.currentDay).format("dddd D MMMM YYYY") +" "+ this.creneau()}
              <span>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousDay}>Jour précédent</Button>
                      </TableCell>
                      <TableCell>
                        <Button style={{marginRight:20+'px', marginTop:10+'px'}} variant="contained" color="secondary" onClick={this.previousPlage}>Plage précédente</Button>
                      </TableCell>
                      <TableCell>
                        <Button style={{marginTop:10+'px'}} variant="contained" color="default" onClick={this.now}>Aujourd'hui</Button>
                      </TableCell>
                      <TableCell>
                        <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextPlage}>Plage suivante</Button>
                      </TableCell>
                      <TableCell>
                        <Button style={{marginLeft:20+'px', marginTop:10+'px'}} variant="contained" color="primary" onClick={this.nextDay}>Jour suivant</Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
      
                </span>
                <List component="nav">
                  <ListItem>
                    <div style={{marginLeft : 44+'%'}} >Créneau horaire</div>
                  </ListItem>
                  <ListItem>
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Sur place" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center'}} primary="Internet" />
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginLeft:100+'px'}} primary="Livraison" />                  
                      <ListItemText style={{fontSize:'0.7em', textAlign : 'center', marginRight:-90+'px'}} primary="Total" />                
                  </ListItem>
                  {this.state.list}
                </List>
            </header>
          )
        }
      }
      
    }else{
      if (this.state.dataError === true){
        return(<div >Erreur, data non complete</div>)
      }else if (this.state.dataError === "dayMissing") {
        return(
          <div >Il manque {7- this.state.deliveryAvailability.length} jour(s) dans la liste [deliveryAvailability] et Il manque {7- this.state.deliveryAvailabilityLivraison.length} jour(s) dans la liste [deliveryAvailabilityLivraison]</div>)
      }else if (this.state.dataError === "dayNullNormal") {
        return(
          <div >Une valeur est null dans la liste [deliveryAvailability]</div>)
      }else if (this.state.dataError === "dayNullLivraison") {
        return(
          <div >Une valeur est null dans la liste [deliveryAvailabilityLivraison]</div>)
      }else {
        return(<div >Erreur de l'obtention de l'API</div>)
      }
    }
  }
    
}



ReactDOM.render(
  <React.StrictMode>
    <Body />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
