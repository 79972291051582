import React from 'react';
import './index.css';
import './App.css';
import axios from 'axios';
import API from './env';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import 'moment/locale/fr';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
require('moment-timezone');

export default class NbOrders extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        date : this.props.date,
        dateLivraison : this.props.dateLivraison,
        normalOrders : this.props.normalOrders,
        livraisonOrders : this.props.livraisonOrders,
        specialOrders : 0,
        normalOrdersMin : this.props.normalOrders,
        livraisonOrdersMin : this.props.livraisonOrders,
        totalLocalOrder : 0,
        maxOrderNormal : this.props.maxOrderNormal,
        maxOrderLivraison : this.props.maxOrderLivraison,
        totalMaxOrders : 0,
        POS_Id : this.props.POS_Id,
        Total : this.props.total || false,
        display : this.props.display,
  
      };
    }
    async componentDidMount () { 
      if(this.state.normalOrders !== undefined && this.state.livraisonOrders !== undefined){
        await this.setState({totalLocalOrder: Number(Number(this.state.livraisonOrders)+Number(this.state.normalOrders))});
        await this.setState({totalMaxOrders: Number(Number(this.state.maxOrderLivraison)+Number(this.state.maxOrderNormal))});
      }else if(this.state.normalOrders === undefined){
        await this.setState({totalLocalOrder: Number(this.state.livraisonOrders)});
        await this.setState({totalMaxOrders: Number(this.state.maxOrderLivraison)});
      }else if(this.state.livraisonOrders === undefined){
        await this.setState({totalLocalOrder: Number(this.state.normalOrders)});
        await this.setState({totalMaxOrders: Number(this.state.maxOrderNormal)});
      }
      let livraisonOrders = JSON.parse(localStorage.getItem("livraison"));
      let normalOrders = JSON.parse(localStorage.getItem("normal"));
      let specialOrders = JSON.parse(localStorage.getItem("special"))
      if(livraisonOrders === null){
        livraisonOrders = {};
      }
      if(normalOrders === null){
        normalOrders = {};
      }
      if(specialOrders === null){
        specialOrders = {};
      }
      if(normalOrders[this.state.date] !== undefined && livraisonOrders[this.state.dateLivraison] !== undefined && this.state.normalOrders !== undefined && this.state.livraisonOrders !== undefined){
        await this.setState({normalOrders :this.state.normalOrders + Number(normalOrders[this.state.date])});
        if(Number(specialOrders[this.state.date])){
          await this.setState({specialOrders :this.state.specialOrders + Number(specialOrders[this.state.date])});  
        }else{await this.setState({specialOrders :this.state.specialOrders})}
        await this.setState({livraisonOrders :this.state.livraisonOrders + Number(livraisonOrders[this.state.dateLivraison])});
        await this.setState({totalLocalOrder :this.state.totalLocalOrder + Number(normalOrders[this.state.date]) + Number(livraisonOrders[this.state.dateLivraison]) + this.state.specialOrders})
      }else if(normalOrders[this.state.date] !== undefined && this.state.normalOrders !== undefined){
        await this.setState({noDeliveryOrder :true});
        await this.setState({normalOrders :this.state.normalOrders + Number(normalOrders[this.state.date])});
        if(Number(specialOrders[this.state.date])){
          await this.setState({specialOrders :this.state.specialOrders + Number(specialOrders[this.state.date])});  
        }else{await this.setState({specialOrders :this.state.specialOrders})}
        await this.setState({totalLocalOrder :this.state.totalLocalOrder + Number(normalOrders[this.state.date]) + this.state.specialOrders});      
      }else if(livraisonOrders[this.state.dateLivraison] !== undefined && this.state.livraisonOrders !== undefined){
        if(Number(specialOrders[this.state.date])){
          await this.setState({specialOrders :this.state.specialOrders + Number(specialOrders[this.state.date])});  
        }else{await this.setState({specialOrders :this.state.specialOrders})}
        await this.setState({livraisonOrders :this.state.livraisonOrders + Number(livraisonOrders[this.state.dateLivraison])});
        await this.setState({totalLocalOrder :this.state.totalLocalOrder + Number(livraisonOrders[this.state.dateLivraison]) + this.state.specialOrders});
        let date;
        if(this.state.date){
          date = this.state.date;
        }else{
          date = this.state.dateLivraison
        }
        if(!localStorage.getItem('Total')){
          const tot = {}
          tot[date] = this.state.totalLocalOrder;
          localStorage.setItem('Total', JSON.stringify(tot))
        }else{
          let Totaux = JSON.parse(localStorage.getItem('Total'));
          Totaux[date] = this.state.totalLocalOrder;
          localStorage.setItem('Total', JSON.stringify(Totaux))          
        }
      }else if(specialOrders[this.state.date] !== undefined && this.state.specialOrders !== undefined){
        await this.setState({specialOrders :this.state.specialOrders + Number(specialOrders[this.state.date])});
        await this.setState({totalLocalOrder :this.state.totalLocalOrder +  Number(specialOrders[this.state.date])});
      }
    }
    IncrementNormalItem = async () => {
      const log = await axios.post(`${API.APIurl}/api/v2/auth/login/admin`, 
      {"username": API.adminUsername, "password": API.adminPassword});
      const JWT = log.data.data;
      if (this.state.normalOrders < this.state.maxOrderNormal){
        await axios.put(`${API.APIurl}/api/pointsOfSale/order/add`, { "date": moment(this.state.date).format("MM/DD/YYYY HH:mm") ,"pos_id" : this.state.POS_Id.normal},{headers: {
          'Content-Type': 'application/json',
          'Authorization': JWT,
        }})
        await this.setState({ normalOrders: this.state.normalOrders + 1 });
        await this.setState({ totalLocalOrder: this.state.totalLocalOrder + 1 });
        if(localStorage.getItem("normal")){
          let normalOrders = JSON.parse(localStorage.getItem("normal"));
          if(normalOrders[this.state.date]){
            normalOrders[this.state.date] = normalOrders[this.state.date] + 1;
          }else{
            normalOrders[this.state.date] = 1;
          }
          await localStorage.setItem("normal", JSON.stringify(normalOrders));
        }else{
          let normalOrders = {};
          normalOrders[this.state.date] = 1;
          await localStorage.setItem("normal", JSON.stringify(normalOrders));
        }
      }
    }
    IncrementLivraisonItem = async () => {
      const log = await axios.post(`${API.APIurl}/api/v2/auth/login/admin`, 
      {"username": API.adminUsername, "password": API.adminPassword});
      const JWT = log.data.data;
      if (this.state.livraisonOrders < this.state.maxOrderLivraison){
        await axios.put(`${API.APIurl}/api/pointsOfSale/order/add`, { "date": moment(this.state.date).format("MM/DD/YYYY HH:mm") ,"pos_id" : this.state.POS_Id.livraison},{headers: {
          'Content-Type': 'application/json',
          'Authorization': JWT,
        }})
        await this.setState({ livraisonOrders: this.state.livraisonOrders + 1 });
        await this.setState({ totalLocalOrder: this.state.totalLocalOrder + 1 });
        if(localStorage.getItem("livraison")){
          let livraisonOrders = JSON.parse(localStorage.getItem("livraison"));
          if(livraisonOrders[this.state.dateLivraison]){
            livraisonOrders[this.state.dateLivraison] = livraisonOrders[this.state.dateLivraison] + 1;
          }else{
            livraisonOrders[this.state.dateLivraison] = 1;
          }
          await localStorage.setItem("livraison", JSON.stringify(livraisonOrders));
        }else{
          let livraisonOrders = {};
          livraisonOrders[this.state.dateLivraison] = 1;
          await localStorage.setItem("livraison", JSON.stringify(livraisonOrders));
        }
      }
    }
    IncrementSpecialItem = async () => {
      await this.setState({ specialOrders: this.state.specialOrders + 1 });
      await this.setState({ totalLocalOrder: this.state.totalLocalOrder + 1 });
      if(localStorage.getItem("special")){
        let specialOrders = JSON.parse(localStorage.getItem("special"));
        specialOrders[this.state.date] = this.state.specialOrders;
        await localStorage.setItem("special", JSON.stringify(specialOrders));
      }else{
        let specialOrders = {};
        specialOrders[this.state.date] = 1;
        await localStorage.setItem("special", JSON.stringify(specialOrders));
      }
    }
    DecreaseNormalItem = async () => {
      const log = await axios.post(`${API.APIurl}/api/v2/auth/login/admin`, 
      {"username": API.adminUsername, "password": API.adminPassword});
      const JWT = log.data.data;
      if(this.state.normalOrders > this.state.normalOrdersMin){
        await axios.put(`${API.APIurl}/api/pointsOfSale/order/remove`, { "date": moment(this.state.date).format("MM/DD/YYYY HH:mm") ,"pos_id" : this.state.POS_Id.normal},{headers: {
          'Content-Type': 'application/json',
          'Authorization': JWT,
        }})
        await this.setState({ normalOrders: this.state.normalOrders - 1 });
        await this.setState({ totalLocalOrder: this.state.totalLocalOrder - 1 });
        if(localStorage.getItem("normal")){
          let normalOrders = JSON.parse(localStorage.getItem("normal"));
          normalOrders[this.state.date] = normalOrders[this.state.date] - 1;
          await localStorage.setItem("normal", JSON.stringify(normalOrders));
        }else{
          let normalOrders = {};
          normalOrders[this.state.date] = 0;
          await localStorage.setItem("normal", JSON.stringify(normalOrders));
        }
      }
    }
    DecreaseLivraisonItem = async () => {
      const log = await axios.post(`${API.APIurl}/api/v2/auth/login/admin`, 
      {"username": API.adminUsername, "password": API.adminPassword});
      const JWT = log.data.data;
      if(this.state.livraisonOrders > this.state.livraisonOrdersMin){
        await axios.put(`${API.APIurl}/api/pointsOfSale/order/remove`, { "date": moment(this.state.dateLivraison).format("MM/DD/YYYY HH:mm") ,"pos_id" : this.state.POS_Id.livraison},{headers: {
          'Content-Type': 'application/json',
          'Authorization': JWT,
        }})
        await this.setState({ livraisonOrders: this.state.livraisonOrders - 1 });
        await this.setState({ totalLocalOrder: this.state.totalLocalOrder - 1 });
        if(localStorage.getItem("livraison")){
          let livraisonOrders = JSON.parse(localStorage.getItem("livraison"));
          livraisonOrders[this.state.dateLivraison] = livraisonOrders[this.state.dateLivraison] - 1;
          await localStorage.setItem("livraison", JSON.stringify(livraisonOrders));
        }else{
          let livraisonOrders = {};
          livraisonOrders[this.state.dateLivraison] = 0;
          await localStorage.setItem("livraison", JSON.stringify(livraisonOrders));
        }
      }
    }
    DecreaseSpecialItem = async () => {
      if(this.state.specialOrders > 0){
        await this.setState({ specialOrders: this.state.specialOrders - 1 });
        await this.setState({ totalLocalOrder: this.state.totalLocalOrder - 1 });
        if(localStorage.getItem("special")){
          let specialOrders = JSON.parse(localStorage.getItem("special"));
          specialOrders[this.state.date] = specialOrders[this.state.date] - 1;
          await localStorage.setItem("special", JSON.stringify(specialOrders));
        }else{
          let specialOrders = {};
          specialOrders[this.state.date] = 0;
          await localStorage.setItem("special", JSON.stringify(specialOrders));
        }
      }
    }
    
    render() {
      if(this.state.Total === false){
        if(this.state.normalOrders !== undefined && this.state.livraisonOrders !== undefined){
          if(this.state.totalLocalOrder >= this.state.totalMaxOrders){
            return (
              <List>
                  <ListItem>
                      <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                      <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                          <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                          <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                          <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                      </span> 
                      <ListItemText style={{marginRight:15+'px', marginLeft:75+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                      <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                          <Button variant="contained" color="secondary" onClick={this.DecreaseNormalItem}>-</Button>
                          <span style={{margin : 5 + 'px'}}>{this.state.normalOrders}/{this.state.maxOrderNormal}</span>
                          <Button variant="contained" color="primary" onClick={this.IncrementNormalItem}>+</Button>
                      </span> 
                      <ListItemText style={{flex: 'initial',marginRight:15+'px',marginLeft:100+'px'}} primary={moment(this.state.dateLivraison).format("HH:mm") + " / " + moment(this.state.dateLivraison).add(1,'hours').format("HH:mm")} />
                      <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                          <Button variant="contained" color="secondary" onClick={this.DecreaseLivraisonItem}>-</Button>
                          <span style={{margin : 5 + 'px'}}>{this.state.livraisonOrders}/{this.state.maxOrderLivraison}</span>
                          <Button variant="contained" color="primary" onClick={this.IncrementLivraisonItem}>+</Button>
                      </span>
                      <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={""} />
                      <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem' ,backgroundColor: 'red'}}>
                        <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                      </span>
                  </ListItem>
              </List>
            );
          }else{
            return (
              <List>
                  <ListItem>
                      <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                      <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                          <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                          <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                          <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                      </span> 
                      <ListItemText style={{marginRight:15+'px', marginLeft:75+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                      <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                          <Button variant="contained" color="secondary" onClick={this.DecreaseNormalItem}>-</Button>
                          <span style={{margin : 5 + 'px'}}>{this.state.normalOrders}/{this.state.maxOrderNormal}</span>
                          <Button variant="contained" color="primary" onClick={this.IncrementNormalItem}>+</Button>
                      </span> 
                      <ListItemText style={{flex: 'initial',marginRight:15+'px',marginLeft:100+'px'}} primary={moment(this.state.dateLivraison).format("HH:mm") + " / " + moment(this.state.dateLivraison).add(1,'hours').format("HH:mm")} />
                      <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                          <Button variant="contained" color="secondary" onClick={this.DecreaseLivraisonItem}>-</Button>
                          <span style={{margin : 5 + 'px'}}>{this.state.livraisonOrders}/{this.state.maxOrderLivraison}</span>
                          <Button variant="contained" color="primary" onClick={this.IncrementLivraisonItem}>+</Button>
                      </span>
                      <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={""} />
                      <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem'}}>
                        <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                      </span>
                  </ListItem>
              </List>
            );
          }
        }else if(this.state.normalOrders !== undefined){ 
          if(this.state.display === "all"){
            if(this.state.totalLocalOrder >= this.state.totalMaxOrders){
              return (
                <List>
                    <ListItem>
                        <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                        </span> 
                        <ListItemText style={{marginRight:15+'px', marginLeft:75+'px', maxWidth: 50 + 'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseNormalItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.normalOrders}/{this.state.maxOrderNormal}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementNormalItem}>+</Button>
                        </span>
                        <ListItemText style={{flex: 'initial',marginRight:290+'px'}} primary={""} />
                        <ListItemText style={{flex: 'initial',marginRight:107+'px'}} primary={""} />
                          <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem',backgroundColor: 'red'}}>
                            <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                          </span>
                    </ListItem>
                </List>
    
              );
            }else{
              return (
                <List>
                    <ListItem>
                        <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                        </span> 
                        <ListItemText style={{marginRight:15+'px', marginLeft:75+'px', maxWidth: 50 + 'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseNormalItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.normalOrders}/{this.state.maxOrderNormal}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementNormalItem}>+</Button>
                        </span>
                        <ListItemText style={{flex: 'initial',marginRight:290+'px'}} primary={""} />
                        <ListItemText style={{flex: 'initial',marginRight:107+'px'}} primary={""} />
                          <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem'}}>
                            <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                          </span>
                    </ListItem>
                </List>
    
              );
            }
          }else{
            if(this.state.totalLocalOrder >= this.state.totalMaxOrders){
              return (
                <List>
                    <ListItem>
                        <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                        </span> 
                        <ListItemText style={{marginRight:15+'px', marginLeft:75+'px', maxWidth: 50 + 'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseNormalItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.normalOrders}/{this.state.maxOrderNormal}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementNormalItem}>+</Button>
                        </span>
                        <ListItemText style={{flex: 'initial',marginRight:290+'px'}} primary={""} />
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={""} />
                          <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem',backgroundColor: 'red'}}>
                            <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                          </span>
                    </ListItem>
                </List>
    
              );
            }else{
              return (
                <List>
                    <ListItem>
                        <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                        </span> 
                        <ListItemText style={{marginRight:15+'px', marginLeft:75+'px', maxWidth: 50 + 'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseNormalItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.normalOrders}/{this.state.maxOrderNormal}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementNormalItem}>+</Button>
                        </span>
                        <ListItemText style={{flex: 'initial',marginRight:290+'px'}} primary={""} />
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={""} />
                          <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem'}}>
                            <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                          </span>
                    </ListItem>
                </List>
    
              );
            }
          }
          
        }else if(this.state.livraisonOrders !== undefined){
          if(this.state.display === "all"){
            if(this.state.totalLocalOrder >= this.state.totalMaxOrders){
              return (
                <List>
                    <ListItem>
                        <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                        </span> 
                        <ListItemText style={{flex: 'initial',marginRight:402+'px'}} primary={""} />
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={moment(this.state.dateLivraison).format("HH:mm") + " / " + moment(this.state.dateLivraison).add(1,'hours').format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseLivraisonItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.livraisonOrders}/{this.state.maxOrderLivraison}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementLivraisonItem}>+</Button>
                        </span>
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={""} />
                        <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem' ,backgroundColor: 'red'}}>
                          <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                        </span>
                    </ListItem>
                </List>
              );
            }else{
              return (
                <List>
                    <ListItem>
                        <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                        </span> 
                        <ListItemText style={{flex: 'initial',marginRight:402+'px'}} primary={""} />
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={moment(this.state.dateLivraison).format("HH:mm") + " / " + moment(this.state.dateLivraison).add(1,'hours').format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseLivraisonItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.livraisonOrders}/{this.state.maxOrderLivraison}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementLivraisonItem}>+</Button>
                        </span>
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={""} />
                        <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem'}}>
                          <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                        </span>
                    </ListItem>
                </List>
              );
            }
          }else{
            if(this.state.totalLocalOrder >= this.state.totalMaxOrders){
              return (
                <List>
                    <ListItem>
                        <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                        </span> 
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={moment(this.state.dateLivraison).format("HH:mm") + " / " + moment(this.state.dateLivraison).add(1,'hours').format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseLivraisonItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.livraisonOrders}/{this.state.maxOrderLivraison}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementLivraisonItem}>+</Button>
                        </span>
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={""} />
                        <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem' ,backgroundColor: 'red'}}>
                          <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                        </span>
                    </ListItem>
                </List>
              );
            }else{
              return (
                <List>
                    <ListItem>
                        <ListItemText style={{marginRight:15+'px', marginLeft:10+'px'}} primary={moment(this.state.date).format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseSpecialItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.specialOrders}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementSpecialItem}>+</Button>
                        </span> 
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={moment(this.state.dateLivraison).format("HH:mm") + " / " + moment(this.state.dateLivraison).add(1,'hours').format("HH:mm")} />
                        <span style={{marginRight : 9+'px', fontSize : 1 + 'rem'}}>
                            <Button variant="contained" color="secondary" onClick={this.DecreaseLivraisonItem}>-</Button>
                            <span style={{margin : 5 + 'px'}}>{this.state.livraisonOrders}/{this.state.maxOrderLivraison}</span>
                            <Button variant="contained" color="primary" onClick={this.IncrementLivraisonItem}>+</Button>
                        </span>
                        <ListItemText style={{flex: 'initial',marginRight:15+'px'}} primary={""} />
                        <span style={{marginRight : 0 + 'px', marginLeft : 115 + 'px',fontSize : 1 + 'rem'}}>
                          <span style={{margin : 5 + 'px'}}>{this.state.totalLocalOrder}/{this.state.totalMaxOrders}</span>
                        </span>
                    </ListItem>
                </List>
              );
            }
          }
        }
      } 
    }
  }